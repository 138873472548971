@use "src/mixins.scss";

.Intro {
  padding-left: var(--horizontal-padding);
  display: flex;
  gap: 64px;

  @include mixins.narrowerThan1440() {
    gap: 60px;
  }

  @include mixins.narrowerThan1240() {
    .FillSearchApplication {
      display: none;
    }
  }

  @include mixins.narrowerThan905() {
    flex-direction: column;
    gap: 0;
    padding: 0;
  }

  .introText {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding: 91px 0;
    gap: 36px;
    display: flex;
    flex-direction: column;

    @include mixins.narrowerThan1240() {
      padding: 61px 0;
    }

    @include mixins.narrowerThan905() {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      padding: 32px var(--horizontal-padding);
    }
    @include mixins.narrowerThan600() {
      padding: 16px var(--horizontal-padding);
    }

    h1 {
      display: none;
    }

    > p {
      margin-top: 0;
      margin-bottom: 0;

      > a {
        color: inherit;
        font-weight: 700;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .introPic {
    object-fit: cover;
    width: 666px;

    @include mixins.narrowerThan1240() {
      width: 332px;
    }
    @include mixins.narrowerThan905() {
      width: 100%;
    }
  }
}
