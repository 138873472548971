@use "src/mixins";

.SearchButton {
  width: 100%;
  a {
    display: flex;
    color: var(--color-black);
    border-bottom: 2px solid var(--color-black);
    text-decoration: none;
    font-family: e-Ukraine;
    text-align: start;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    font-size: 36px;
    font-weight: 400;
    line-height: 1;
    height: 64px;

    @include mixins.narrowerThan600() {
      font-size: 28px;
    }

    .SearchIcon {
      display: unset;
    }
    .SoldierLogo {
      display: none;
    }

    &:hover {
      .SearchIcon {
        display: none;
      }
      .SoldierLogo {
        display: unset;
      }
    }
  }
}
