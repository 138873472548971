@use "src/mixins.scss";

.HeaderButtons {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
  //padding-inline-start: 16px;
  //width: 550px;
  max-width: 718px;
  gap: 16px;

  @include mixins.narrowerThan1440() {
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-inline-start: 0;
  }

  @include mixins.narrowerThan600() {
    flex-direction: row;

    .SmButtons ul {
      flex-wrap: wrap;
    }
    .chatbot-link {
      height: 56px !important;
    }
  }

  .SmButtons {
    width: auto;
    flex: 1 0 auto;
    --sm-color-foreground: var(--color-black);
    --sm-color-background: var(--color-grey);

    ul {
      justify-content: space-between;
      gap: 0;
    }

    li,
    li a,
    li a svg {
      @include mixins.narrowerThan905() {
        font-size: 32px;
        height: 32px;
        width: 32px;
      }
    }
  }

  .chatbot-link {
    width: 248px;

    @include mixins.narrowerThan905() {
      font-size: 24px;
      height: 40px;
    }
  }
}
