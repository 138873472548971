@use "src/mixins.scss";

.HowPowsLive {
  .title {
    color: var(--color-black);
    background-color: var(--color-primary);

    padding: 32px var(--horizontal-padding);
    margin: 0;
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    @include mixins.narrowerThan905() {
      font-size: 30px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
