.Carousel {
  height: 496px;
  position: relative;

  .itemsContainer {
    margin: 0;
    padding: 0;

    list-style-type: none;
    width: auto;
    height: 100%;
    overflow: hidden;
    display: flex;
    overscroll-behavior: contain;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    li {
      display: flex;
      width: 100%;
      flex-shrink: 0;
      height: 100%;
      overflow: hidden;
      scroll-snap-align: center;
      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 var(--horizontal-padding);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .left,
    .right {
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}
