@use "src/mixins";

.CondensedLogo {
  display: flex;

  @include mixins.narrowerThan1440() {
    flex-grow: 0;
  }

  @include mixins.narrowerThan600() {
    grid-row: 1;
  }

  svg {
    @include mixins.narrowerThan1440() {
      width: 128.13px;
      height: 147.4px;
    }
    @include mixins.narrowerThan905() {
      width: 155.87px;
      height: 179.31px;
    }
    @include mixins.narrowerThan600() {
      width: 111.51px;
      height: 128.28px;
    }
  }
}
