@use "src/mixins";

.HowItWorksIntro {
  border: 2px solid var(--color-primary);
  border-radius: 40px;
  background: var(--color-primary);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  font-size: 24px;
  line-height: 1.2;

  @include mixins.narrowerThan905() {
    border-radius: 20px;
    font-size: 20px;
  }

  @include mixins.narrowerThan600() {
    text-align: start;
  }
}
