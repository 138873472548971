@use "src/mixins.scss";

.Header {
  padding: 0 var(--horizontal-padding);
  background-color: var(--color-primary);

  .headerContentContainer {
    display: flex;
    margin: 55px 0;
    gap: 80px;
    justify-content: space-between;
    align-items: center;

    @include mixins.narrowerThan905() {
      margin: 32px 0;
    }

    @include mixins.narrowerThan600() {
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin: 16px 0 32px;
    }
  }
}
