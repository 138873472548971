.LinkButton {
  border-radius: 15px; //
  height: 53px; //

  border: 2px solid var(--color-black);
  font-weight: 400;
  letter-spacing: -2%;
  text-align: center;
  color: var(--color-black);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  text-decoration: none;
}

// Variants

.mostPopular {
  border-radius: 200px;
  height: 80px;
  width: 296px;
  flex-shrink: 0;
  font-size: 24px;
  line-height: 24px;

  &:hover {
    background-color: var(--color-black);
    color: var(--color-grey);
    box-shadow: 0px 21px 35px 0px rgba(0, 0, 0, 0.1);
  }
}

.highlightOnHover {
  font-size: 27px;
  line-height: 27px;
  border-radius: 200px;

  &:hover {
    background-color: var(--color-black);
    color: var(--color-grey);
    box-shadow: 0px 21px 35px 0px rgba(0, 0, 0, 0.1);
  }
}

.underlined {
  padding: 10px 32px;
  gap: 10px;
  height: 73px;
  border-radius: 40px;
  border: 2px solid #ffffff;
  color: inherit;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: inherit;
  text-decoration: underline;

  &:hover {
    background-color: var(--color-black);
    color: var(--color-primary);
    border-color: var(--color-black);
  }
}
