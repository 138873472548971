@use "src/mixins";

.WhereWeSearchTile {
  background-color: #ffffff;
  border-radius: 40px;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mixins.narrowerThan905() {
    border-radius: 20px;
    padding: 16px;
    gap: 16px;
  }

  .title {
    text-transform: uppercase;
    color: var(--color-black);
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: -0.02em;
    text-align: left;

    @include mixins.narrowerThan905() {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .divider {
    border-bottom: 2px solid #000000;
  }

  .content {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

    @include mixins.narrowerThan905() {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
