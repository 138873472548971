@use "src/mixins";

.FillSearchApplication {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;

    @include mixins.narrowerThan905() {
      gap: 20px;
    }

    @include mixins.narrowerThan600() {
      flex-direction: column;
    }

    .hint {
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .divider {
    border-bottom: 2px solid #7694ff;
  }
}
