@use "src/mixins.scss";

.WhereWeSearch {
  padding: 105px var(--horizontal-padding);
  background: var(--color-grey);
  display: flex;
  flex-direction: column;
  gap: 60px;

  @include mixins.narrowerThan1440() {
    padding-top: 74px;
  }

  @include mixins.narrowerThan905() {
    border-radius: 40px;
    padding: 32px var(--horizontal-padding);
    gap: 32px;
  }

  @include mixins.narrowerThan600() {
    padding: 16px var(--horizontal-padding);
    gap: 10px;
  }

  h3 {
    font-size: 38px;
    line-height: 40px;
    font-weight: 300;
    margin: 0;
  }

  .tilesWrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    margin-bottom: -143px;
    @include mixins.narrowerThan905() {
      margin-bottom: 0;
      gap: 32px;
    }
    @include mixins.narrowerThan600() {
      margin-bottom: 0;
      gap: 16px;
    }

    .tilesContainer {
      display: grid;
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);

      @include mixins.narrowerThan1240() {
        grid-template-columns: 1fr;
      }
      @include mixins.narrowerThan905() {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mixins.narrowerThan600() {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }

    .LinkButton {
      background-color: #ffffff;
      &:hover {
        background-color: var(--color-black);
      }
    }
  }
}
