@use "src/mixins.scss";

.SmButtons {
  // --sm-color-foreground: var(--color-primary);
  // --sm-color-background: var(--color-grey);

  ul {
    list-style-type: none;
    display: flex;
    gap: 29.5px;
    padding: 0;
    margin: 0;

    @include mixins.narrowerThan1440() {
      gap: 14.5px;
    }
    @include mixins.narrowerThan905() {
      gap: 8px;
    }

    li {
      a {
        text-decoration: none;
        > * {
          color: var(--sm-color-foreground);
          font-size: 50px;
          text-decoration: none;
          display: block;

          @include mixins.narrowerThan905() {
            font-size: 44px;
            height: 44px;
            width: 44px;
          }

          .border {
            fill: var(--sm-color-foreground);
          }
          .content {
            fill: var(--sm-color-foreground);
          }

          &:hover {
            .hoverfill,
            .border {
              fill: var(--sm-color-foreground);
            }
            .content {
              fill: var(--sm-color-background);
            }
          }
        }
      }
    }
  }
}
