@use "src/mixins";

.HowItWorks {
  padding: 0 var(--horizontal-padding);
  gap: 64px;
  display: flex;
  flex-direction: column;
  @include mixins.narrowerThan905() {
    gap: 32px;
  }
  h2 {
    font-size: 38px;
    line-height: 40px;
    font-weight: 300;
    margin: 0;

    @include mixins.narrowerThan905() {
      font-size: 34px;
    }
  }

  .tilesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, minmax(269px, auto));
    gap: 16px;

    @include mixins.narrowerThan1240() {
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mixins.narrowerThan905() {
      gap: 12px;
      grid-template-columns: 1fr;
      grid-template-rows: none;
    }
  }
}
