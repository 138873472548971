@use "src/mixins.scss";

.Faq {
  background-color: var(--color-grey);
  padding: 60px var(--horizontal-padding);
  display: flex;
  flex-direction: column;
  gap: 36px;

  @include mixins.narrowerThan1440() {
    padding: 45px var(--horizontal-padding);
  }

  .title {
    margin: 0;
    padding: 0px 44px;
    border-radius: 40px;
    border: 2px solid var(--color-black);
    display: flex;
    height: 118px;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    font-size: 38px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-black);

    @include mixins.narrowerThan905() {
      padding: 16px;
      font-size: 20px;
      height: auto;
    }

    @include mixins.narrowerThan600() {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .content {
    ul {
      margin: 0 45px;
      // list-style-type: disclosure-open;
      // list-style-image: url(./keyboard_arrow_down.svg);
      // list-style-position: outside;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 45px;
      padding: 0;

      @include mixins.narrowerThan905() {
        margin: 0;
      }
    }
  }
}
