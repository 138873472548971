@use "src/mixins";

.Tile {
  border: 6px solid var(--color-primary);
  border-radius: 40px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;

  @include mixins.narrowerThan905() {
    border-radius: 20px;
    padding: 16px;
  }

  .title {
    color: var(--color-black);
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    @include mixins.narrowerThan905() {
      font-size: 20px;
    }
  }

  .content {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

    @include mixins.narrowerThan905() {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
