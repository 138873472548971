@use "src/mixins.scss";

.FaqItem {
  display: flex;
  flex-direction: row;
  gap: 20px;
  &::before {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url(./keyboard_arrow_down.svg);
    transition: rotate 0.3s;
    rotate: -90deg;
    display: block;
    flex-shrink: 0;
    translate: 0 -4px;
    cursor: pointer;
  }

  &.expanded::before {
    rotate: 0deg;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      cursor: pointer;

      @include mixins.narrowerThan905() {
        font-size: 14px;
        line-height: 16.79px;
      }
    }

    .content {
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      display: none;

      @include mixins.narrowerThan905() {
        font-size: 14px;
        line-height: 16.79px;
      }
    }
  }

  &.expanded {
    .content {
      display: block;
    }
  }
}
