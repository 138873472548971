@use "src/mixins";

.Layout {
  display: flex;
  flex-direction: column;
  gap: 140px;

  @include mixins.narrowerThan1440() {
    gap: 100px;
  }

  @include mixins.narrowerThan905() {
    gap: 32px;
  }

  > .FillSearchApplication {
    padding: 0 var(--horizontal-padding);
    display: none;

    @include mixins.narrowerThan1240() {
      display: flex;
    }

    @include mixins.narrowerThan905() {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include mixins.narrowerThan600() {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
