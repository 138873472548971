// NOTE: widths include the scrollbar width

@mixin narrowerThan1440() {
  @media (max-width: calc(1440px - 1px + 17px)) {
    @content;
  }
}

@mixin narrowerThan1240() {
  @media (max-width: calc(1240px - 1px + 17px)) {
    @content;
  }
}

@mixin narrowerThan905() {
  @media (max-width: calc(905px - 1px + 17px)) {
    @content;
  }
}

@mixin narrowerThan600() {
  @media (max-width: calc(600px - 1px + 17px)) {
    @content;
  }
}
