@use "src/mixins.scss";
@import "src/colors.scss";

body {
  margin: 0;
  font-family: "e-Ukraine";
  color: #041030;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  --horizontal-padding: 136px;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-underline-offset: -1px;

  @include mixins.narrowerThan1440() {
    --horizontal-padding: 74px;
  }

  @include mixins.narrowerThan905() {
    --horizontal-padding: 32px;
  }

  @include mixins.narrowerThan600() {
    --horizontal-padding: 16px;
  }
}
