@use "src/mixins.scss";

.LogoAndMotto {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  position: relative;
  text-decoration: none;
  color: inherit;

  @include mixins.narrowerThan600() {
    width: 100%;

    picture {
      display: block;
      max-height: 125px;
      &,
      & img {
        width: 100%;
      }
    }
  }
}
