@use "src/mixins.scss";

.HowToSurrender {
  margin: 0 var(--horizontal-padding);
  border-radius: 40px;
  border: 6px solid var(--color-primary);
  display: grid;
  column-gap: 37px;
  row-gap: 32px;
  padding: 32px;
  --khzh-logo-size: 500px;
  grid-template-rows: 1fr minmax(0, auto) 1fr;
  grid-template-columns: auto var(--khzh-logo-size);
  box-sizing: border-box;

  @include mixins.narrowerThan1440() {
    --khzh-logo-size: 400px;
    row-gap: 30px;
  }

  @include mixins.narrowerThan1240() {
    --khzh-logo-size: 300px;
    row-gap: 30px;
  }

  @include mixins.narrowerThan905() {
    --khzh-logo-size: 200px;
    column-gap: 5px;
  }

  @include mixins.narrowerThan600() {
    gap: 32px;
    flex-direction: column;
    padding: 16px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
  }

  .title {
    font-weight: 300;
    font-size: 38px;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    color: var(--color-black);
    align-self: end;
    text-transform: uppercase;
    @include mixins.narrowerThan1240() {
      grid-column: 1 / span 2;
      grid-row: 1;
    }
    @include mixins.narrowerThan905() {
      font-size: 34px;
    }
    @include mixins.narrowerThan600() {
      grid-column: 1 / span 1;
    }
  }
  .content {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

    @include mixins.narrowerThan905() {
      font-size: 24px;
      line-height: 30px;
    }

    @include mixins.narrowerThan600() {
      grid-column: 1;
      grid-row: 2;
    }
  }

  .LinkButton {
    @include mixins.narrowerThan905() {
      // margin-top: 15px;
    }

    @include mixins.narrowerThan600() {
      justify-self: center;
      margin-top: 0;
      grid-column: 1;
      width: 100%;
    }
  }

  .logoContainer {
    grid-row: 1/ 4;
    grid-column: 2;
    display: flex;
    align-items: center;

    @include mixins.narrowerThan1240() {
      grid-column: 2 / span 1;
      grid-row: 2 / span 2;
    }

    @include mixins.narrowerThan600() {
      justify-self: center;
      grid-column: 1 / span 1;
      grid-row: 3;
    }

    a {
      display: flex;
      width: var(--khzh-logo-size);
      height: var(--khzh-logo-size);

      img {
        aspect-ratio: 1 / 1;
        @include mixins.narrowerThan600() {
          max-width: 400px;
        }
      }
    }
  }
}
