.CarouselDots {
  padding: 0 var(--horizontal-padding);

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
      display: flex;
      .button {
        border: none;
        border-bottom: 2px solid transparent;
        border-bottom-color: var(--color-primary);
        padding: 0;
        width: 100%;
        // cursor: pointer;
        &.active {
          border-bottom-color: var(--color-black);
        }
      }
    }
  }
}
